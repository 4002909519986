.stream-chat-container {
    height: 100vh;
    background-color: #f7f7f8;
    display: flex;
    flex-direction: column;
}

.stream-chat-window {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: white;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
}

/* Channel Header */
.stream-channel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid #e9eaed;
    background: white;
}

.stream-header-left {
    display: flex;
    align-items: center;
    gap: 12px;
}

.stream-back-button {
    padding: 8px;
    border-radius: 8px;
    border: none;
    background: transparent;
    cursor: pointer;
    color: #72767E;
}

.stream-back-button:hover {
    background: #f2f2f2;
}

.stream-channel-avatar {
    width: 40px;
    height: 40px;
    border-radius: 12px;
}

.stream-channel-info {
    display: flex;
    flex-direction: column;
}

.stream-channel-name {
    font-size: 16px;
    font-weight: 600;
    color: #2c2c30;
    margin: 0;
}

.stream-channel-members {
    font-size: 13px;
    color: #72767E;
}

.stream-header-right {
    display: flex;
    gap: 8px;
}

.stream-header-action {
    padding: 8px;
    border-radius: 8px;
    border: none;
    background: transparent;
    cursor: pointer;
    color: #72767E;
}

.stream-header-action:hover {
    background: #f2f2f2;
}

/* Message List */
.stream-message-list {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.stream-message {
    display: flex;
    gap: 12px;
    max-width: 70%;
}

.stream-message-mine {
    margin-left: auto;
    flex-direction: row-reverse;
}

.stream-message-avatar {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    flex-shrink: 0;
}

.stream-message-content {
    background: #f2f2f2;
    padding: 12px 16px;
    border-radius: 16px;
    position: relative;
}

.stream-message-mine .stream-message-content {
    background: #005fff;
    color: white;
}

.stream-message-author {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 4px;
    color: #2c2c30;
}

.stream-message-text {
    font-size: 15px;
    line-height: 1.4;
}

.stream-message-meta {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 4px;
    font-size: 12px;
    color: #72767E;
}

.stream-message-mine .stream-message-meta {
    color: rgba(255,255,255,0.8);
}

/* Message Input */
.stream-message-input {
    padding: 16px;
    border-top: 1px solid #e9eaed;
    display: flex;
    align-items: center;
    gap: 12px;
    background: white;
}

.stream-input-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    background: #f2f2f2;
    border-radius: 24px;
    padding: 8px 16px;
}

.stream-input {
    flex: 1;
    border: none;
    background: transparent;
    padding: 8px;
    font-size: 15px;
    outline: none;
}

.stream-input-buttons {
    display: flex;
    gap: 8px;
}

.stream-input-action {
    padding: 8px;
    border-radius: 8px;
    border: none;
    background: transparent;
    cursor: pointer;
    color: #72767E;
}

.stream-send-button {
    padding: 12px;
    border-radius: 50%;
    border: none;
    background: #005fff;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;
}

.stream-send-button:hover {
    background: #0049cc;
}

.stream-send-button:disabled {
    background: #e9eaed;
    cursor: not-allowed;
}

.stream-message-status {
    color: #005fff;
}